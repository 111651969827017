<template>
  <!--公司资质-->
  <div class="zj_companyQualification">
    <div class="cnname black font16rem flexcc wow bounceIn"
    data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1">{{$t('公司资质')}}</div>
    <div class="enname black font10rem flexcc wow bounceIn"
    data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1">Company Qualification</div>
    <div class="qualification flexrow">
      <div
        class="qualification1 wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
      >
        <div class="zz1">
          <div class="zz_pic flexlrend flextbc">
            <div class="pic flexcc">
              <img src="../assets/Images/zz1.png" />
            </div>
          </div>
          <div class="zz_name flexlrend flextbc font14rem black">{{$t('5项资质')}}</div>
          <div :class="$i18n.locale == 'CN'?'zz_info1 flexlrend flextbc font8rem black2':'zz_info1EN flexlrend flextbc font8rem black2'">
            {{$t('CMMI3资质、IT产品信息安全认证')}}
          </div>
          <div :class="$i18n.locale == 'CN'?'zz_info1 flexlrend flextbc font8rem black2':'zz_info1EN flexlrend flextbc font8rem black2'">
         ISO9001、ISO20000、ISO27001
          </div>
        </div>
        <div class="zz1">
          <div class="zz_pic flexlrend flextbc">
            <div class="pic flexcc">
              <img src="../assets/Images/zz3.png" />
            </div>
          </div>
          <div class="zz_name flexlrend flextbc font14rem black">
            {{$t('12项行业荣誉')}}
          </div>
          <div :class="$i18n.locale == 'CN'?'zz_info1 flexlrend flextbc font8rem black2':'zz_info1EN flexlrend flextbc font8rem black2'">
           {{$t('荣获 “中国时尚行业信息化技术与方案创新奖”')}} 
          </div>
          <div :class="$i18n.locale == 'CN'?'zz_info1 flexlrend flextbc font8rem black2':'zz_info1EN flexlrend flextbc font8rem black2'">
            {{$t('荣获“时尚商业信息化优秀IT供应商”')}}
          </div>
          <div :class="$i18n.locale == 'CN'?'zz_info1 flexlrend flextbc font8rem black2':'zz_info1EN flexlrend flextbc font8rem black2'">......</div>
        </div>
      </div>
      <div class="qualification2 flexcc wow pulse"
       data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1">
        <div class="swiper-container zs_container">
          <div class="swiper-wrapper">
            <div class="swiper-slide flexcc">
              <img src="../assets/Images/zs1.png" />
            </div>
            <div class="swiper-slide flexcc">
              <img src="../assets/Images/zs2.png" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="qualification1 wow bounceInRight"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div class="zz1">
          <div class="zz_pic flexlrstart flextbc">
            <div class="pic flexcc">
              <img src="../assets/Images/zz2.png" />
            </div>
          </div>
          <div class="zz_name flexlrstart flextbc font14rem black">
           {{$t('20项+政府荣誉称号')}}
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
           {{$t('国家高新技术企业')}} 
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
           {{$t('国家科技型中小企业')}} 
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
          {{$t('江苏省专精特新软件企业')}}  
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
           {{$t('获批姑苏创新创业领军人才计划')}} 
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
            ......
          </div>
        </div>
        <div class="zz1">
          <div class="zz_pic flexlrstart flextbc">
            <div class="pic flexcc">
              <img src="../assets/Images/zz4.png" />
            </div>
          </div>
          <div class="zz_name flexlrstart flextbc font14rem black">
          {{$t('53个软件著作权')}}
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
          {{$t('新零售系统软件、移动新零售软件')}}  
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
          {{$t('数据中台、快速开发平台工具')}}
          </div>
          <div class="zz_info1 flexlrstart flextbc font8rem black2">
            ......
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import Swiper from "swiper";
export default {
  name: "zj_companyQualification",
  data() {
    return {};
  },
  watch: {},
  mounted() {
    this.swiperZs();
    new this.$wow.WOW({ live: false }).init();
  },
  methods: {
    clickTo() {
      console.log("跳转至查看更多页面");
    },
    swiperZs() {
      let swiperzs = new Swiper(".zs_container", {
        direction: "horizontal",
        effect: "flip",
        flip: {
          slideShadows: false,
          limitRotation: true,
        },
        autoplay: 3000,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlides: true,
        slidesPerView: 1,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_companyQualification {
  width: 100%;
  height: 100%;
  .cnname {
    width: 100%;
    height: 3rem;
    padding-top: 3rem;
  }
  .enname {
    width: 100%;
    height: 2rem;
  }
  .qualification {
    width: 100%;
    height: 75%;
    .qualification1 {
      width: 35%;
      height: 100%;
      .zz1 {
        width: 100%;
        height: 50%;
        .zz_pic {
          width: 100%;
          height: 30%;
          .pic {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 10rem;
            border: 2px solid rgba(39, 132, 242, 0.4);
            img {
              width: 60%;
            }
          }
        }
        .zz_name {
          width: 100%;
          height: 15%;
        }
        .zz_info1 {
          width: 100%;
          height: 10%;
        }
        .zz_info1EN {
          width: 94%;
          height: 12%;
          margin-left: 2rem;
        }
      }
    }
    .qualification2 {
      width: 40%;
      height: 100%;
      background-image: url("../assets/Images/zz.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      .zs_container {
        width: 100%;
        height: 100%;
      }
      img {
        width: 80%;
      }
    }
  }
}
</style>
