export default {
    // 顶部栏
    '首页':  '首页',
    '关于我们':    '关于我们',
    '产品展示':    '产品展示',
    '客户案例':    '客户案例',
    '4000-620-530':    '4000-620-530',
    //底部栏
    '电话联系': '电话联系',
    '4000-620-530':    '4000-620-530',
    '公司地址':    '公司地址',
    ' 江苏省苏州市常熟市高新技术产业开发区湖山路2号同济科技广场1幢14层':    ' 江苏省苏州市常熟市高新技术产业开发区湖山路2号同济科技广场1幢14层',
    '公司邮箱':    '公司邮箱',
    'Johnson.Cao@oneerp.com.cn':    'Johnson.Cao@oneerp.com.cn',
    '扫一扫，了解更多':    '扫一扫，了解更多',
    '苏ICP备18048308号':    '苏ICP备18048308号',
    '© 魔元术(苏州)信息科技有限公司':    '© 魔元术(苏州)信息科技有限公司',
    '首页':    '首页',
    '关于我们':    '关于我们',
    '公司介绍':    '公司介绍',
    '公司资质':    '公司资质',
    '核心团队':    '核心团队',
    '企业动态':    '企业动态',
    '产品展示':    '产品展示',
    '快速开发平台':    '快速开发平台',
    '新零售一体化系统':    '新零售一体化系统',
    'POS+智慧收银系统':    'POS+智慧收银系统',
    '魔数智屏':    '魔数智屏',
    '零售数字化移动BI':    '零售数字化移动BI',
    '全渠道营销与私域流量管理':    '全渠道营销与私域流量管理',
    '服务案例':    '服务案例',
    '经典客户案例':    '经典客户案例',
    '国内外知名品牌':    '国内外知名品牌',
    '专业零售商':    '专业零售商',
    // 首页
    '新零售数字化转型专家':  '新零售数字化转型专家',
    '驱动智慧零售':    '驱动智慧零售',
    '助力企业成长':    '助力企业成长',
    '联系商务合作':    '联系商务合作',
    '联系我们':    '联系我们',
    '联系电话':    '联系电话',
    '4000-620-530':    '4000-620-530',
    '公司邮箱':    '公司邮箱',
    'Johnson.Cao@oneerp.com.cn':    'Johnson.Cao@oneerp.com.cn',
    '公司地址':    '公司地址',
    '江苏省苏州市常熟市高新技术产业开发区湖山路2号同济科技广场1幢14层':    '江苏省苏州市常熟市高新技术产业开发区湖山路2号同济科技广场1幢14层',
    '行业项目经验':    '行业项目经验',
    '19年+':    '19年+',
    '技术人员':    '技术人员',
    '60+':    '60+',
    '服务门店数':    '服务门店数',
    '5W+':    '5W+',
    '品牌客户':    '品牌客户',
    '50+':    '50+',
    '70+':    '70+',
    '合作大项目':    '合作大项目',
    '20+':    '20+',

    '关于我们':'关于我们',
    '魔元术于2018年7月成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。': '魔元术于2018年7月成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。',
    '公司专业从事零售、服装行业、运动细分行业的互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理，帮助企业建立数字化竞争优势。': '公司专业从事零售、服装行业、运动细分行业的互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理，帮助企业建立数字化竞争优势。',
    '查': '查',
    '看更多':    '看更多',
    '特色服务':    '特色服务',
    '新思维':    '新思维',
    '用户思维实现场景化,管理思维实现快速通用化':    '用户思维实现场景化,管理思维实现快速通用化',
    '新零售':    '新零售',
    '一切围绕新零售贴着零售干':    '一切围绕新零售贴着零售干',
    '新能力':    '新能力',
    '快速解决问题的能力就是创新':    '快速解决问题的能力就是创新',
    '新技术':    '新技术',
    '平台化快速化业务场景化通用颗粒化技术':    '平台化快速化业务场景化通用颗粒化技术',
    '新服务':    '新服务',
    '成就客户就是成就自我。言必行、行必果、己诺必诚':    '成就客户就是成就自我。言必行、行必果、己诺必诚',
    '业务范围':    '业务范围',
    '新零售一体化':    '新零售一体化',
    '快速开发工具':    '快速开发工具',
    '智慧数据中台':    '智慧数据中台',
    '全渠道营销':    '全渠道营销',
    '私域流量管理':    '私域流量管理',
    '全渠道订单路由':    '全渠道订单路由',
    '客户案例':    '客户案例',
    '晨光九木杂物社':    '晨光九木杂物社',
    '魔元术新零售一体化系统':    '魔元术新零售一体化系统',
    '零售、批发、电商一体化':    '零售、批发、电商一体化',
    'POS、DRP、O2O订单路由、电商EB含OMS、移动BI等':    'POS、DRP、O2O订单路由、电商EB含OMS、移动BI等',
    '魔元术新零售一体化系统':    '魔元术新零售一体化系统',
    '多事业部/多品牌/多财务核算体':    '多事业部/多品牌/多财务核算体',
    '终端POS、终端HR（考勤管理）、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMSDST&KL）':    '终端POS、终端HR（考勤管理）、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMSDST&KL）',
    '太平鸟':    '太平鸟',
    '魔元术新零售一体化系统':    '魔元术新零售一体化系统',
    '多种形式POS、DRP、OMS、CRM、移动BI、新零售数据中台':    '多种形式POS、DRP、OMS、CRM、移动BI、新零售数据中台',
    '强大的技术实力，持续引领市场':    '强大的技术实力，持续引领市场',
    '十九年的经验积累，产品精打细磨，客户口碑相传':    '十九年的经验积累，产品精打细磨，客户口碑相传',
    '太平鸟':    '太平鸟',
    '波司登':    '波司登',
    '晨光':    '晨光',
    '森马':    '森马',
    '雅诗兰黛':    '雅诗兰黛',
    '海棠铺子':    '海棠铺子',
    '戎美':    '戎美',
    '绿叶':    '绿叶',
    '木槿':    '木槿',
    '九木':    '九木',
    '热风':    '热风',
    '可隆':    '可隆',
    '迪桑特':    '迪桑特',
    // 关于我们
    '新零售数字化转型专家': '新零售数字化转型专家',
    '行业领先新零售一体化系统及解决方案服务商':    '行业领先新零售一体化系统及解决方案服务商',
    '联系商务合作':    '联系商务合作',

    '公司介绍': '公司介绍',
    '魔元术公司于2018年7月在常熟成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。': '魔元术公司于2018年7月在常熟成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。',
    '公司专业从事零售、服装行业、运动细分行业的移动互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理。通过运用大数据、人工智能等先进技术，对零售业商品的生产、流通与销售过程进行升级改造，帮助企业建立数字化竞争优势，达成渠道一体化、卖场智能化、商品社会化，打造经营数字化（主要包括营销数字化、门店数字化、供应链数字化、店员数字化、会员数字化、商品数字化的企业数字化进程问题）。': '公司专业从事零售、服装行业、运动细分行业的移动互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理。通过运用大数据、人工智能等先进技术，对零售业商品的生产、流通与销售过程进行升级改造，帮助企业建立数字化竞争优势，达成渠道一体化、卖场智能化、商品社会化，打造经营数字化（主要包括营销数字化、门店数字化、供应链数字化、店员数字化、会员数字化、商品数字化的企业数字化进程问题）。',
    '具有目前移动互联网行业内最先进的积木式、魔方式的快速配置、开发、交付能力，现已成为该行业内最大的模块化与开发软件的平台，彻底解决了零售批发电商一体化的软件问题。': '具有目前移动互联网行业内最先进的积木式、魔方式的快速配置、开发、交付能力，现已成为该行业内最大的模块化与开发软件的平台，彻底解决了零售批发电商一体化的软件问题。',

    '公司资质':'公司资质',
    '5项资质':    '5项资质',
    'CMMI3资质、IT产品信息安全认证':    'CMMI3资质、IT产品信息安全认证',
    'ISO9001、ISO20000、ISO27001':    'ISO9001、ISO20000、ISO27001',
    '12项行业荣誉':    '12项行业荣誉',
    '荣获 “中国时尚行业信息化技术与方案创新奖”':    '荣获 “中国时尚行业信息化技术与方案创新奖”',
    '20项+政府荣誉称号':    '20项+政府荣誉称号',
    '国家高新技术企业':    '国家高新技术企业',
    '国家科技型中小企业':    '国家科技型中小企业',
    '江苏省专精特新软件企业':    '江苏省专精特新软件企业',
    '获批姑苏创新创业领军人才计划':    '获批姑苏创新创业领军人才计划',
    '53个软件著作权':    '53个软件著作权',
    '新零售系统软件、移动新零售软件':    '新零售系统软件、移动新零售软件',
    '数据中台、快速开发平台工具':    '数据中台、快速开发平台工具',

    '团队介绍':'团队介绍',
    '张学良':    '张学良',
    '创始人兼CEO':    '创始人兼CEO',
    '比利时列日大学硕士，中国管理科学研究院学术委员会特约研究员、姑苏科技创业领军人才、常熟市“科技创新创业领军人才”（A类），资深项目管理专家，国内首批获得PMP证书的专业项目管理人才，拥有18年以上零售行业开发和项目管理经验，国内“一个公司一套业务系统”提出者和缔造者,且是一体化先行与倡导者。获得过最佳管理、最佳项目、昆山市人才等多项荣誉，曾担任江苏省专家资源库专家，现为苏州市工信领域大数据行业专家。': '比利时列日大学硕士，中国管理科学研究院学术委员会特约研究员、姑苏科技创业领军人才、常熟市“科技创新创业领军人才”（A类），资深项目管理专家，国内首批获得PMP证书的专业项目管理人才，拥有18年以上零售行业开发和项目管理经验，国内“一个公司一套业务系统”提出者和缔造者,且是一体化先行与倡导者。获得过最佳管理、最佳项目、昆山市人才等多项荣誉，曾担任江苏省专家资源库专家，现为苏州市工信领域大数据行业专家。',
    '宋之民':'宋之民',
    '毕业于北京大学信息管理与系统专业，资深平台专家，已有18年零售行业平台和定制经验。对平台抽象开发、零售业务开发以及接口抽象开发等有丰富的经验，有移动互联网行业丰富的流程、现场开发与团队开发管理经验。': '毕业于北京大学信息管理与系统专业，资深平台专家，已有18年零售行业平台和定制经验。对平台抽象开发、零售业务开发以及接口抽象开发等有丰富的经验，有移动互联网行业丰富的流程、现场开发与团队开发管理经验。',
    '曹小强': '曹小强',
    '毕业于中国地质大学计算机应用专业，是中国较早从事计算机开发的人才,且是国内资深的数据库开发专家，对平台配置和优化有丰富的经验,有丰富的零售管理经验;具有19年鞋服行业信息化项目经验。':'毕业于中国地质大学计算机应用专业，是中国较早从事计算机开发的人才,且是国内资深的数据库开发专家，对平台配置和优化有丰富的经验,有丰富的零售管理经验;具有19年鞋服行业信息化项目经验。',
    '吴昌成': '吴昌成',
    '开发总监':    '开发总监',
    '曾任盐城师范学院信息与计算科学专业老师，拥有13年的.NET开发经验,是开发创新型人才。精通.NET Framework框架、熟悉Entity Framework、MVC框架； 熟练掌握WCF；熟练应用RabbitMQ、Kafka等Q类组件应用技术,Redis、Memcached等缓存类组件应用技术 ；理解各种设计模式及其使用场景，精通多线程开发。': '曾任盐城师范学院信息与计算科学专业老师，拥有13年的.NET开发经验,是开发创新型人才。精通.NET Framework框架、熟悉Entity Framework、MVC框架； 熟练掌握WCF；熟练应用RabbitMQ、Kafka等Q类组件应用技术,Redis、Memcached等缓存类组件应用技术 ；理解各种设计模式及其使用场景，精通多线程开发。',

    '公司动态': '公司动态',
    '月':    '月',
    '日':    '日',

    //产品展示
    '快速开发平台':    '快速开发平台',
    '"一个公司、一套业务系统"':    '"一个公司、一套业务系统"',
    '新零售一体化系统':    '新零售一体化系统',
    'POS+智慧收银系统':    'POS+智慧收银系统',
    '打造品牌的超级门店   实现其全数字化经营':    '打造品牌的超级门店   实现其全数字化经营',
    '魔数智屏':    '魔数智屏',
    '重新定义数据可视化   提升客户数据洞悉能力':    '重新定义数据可视化   提升客户数据洞悉能力',
    '零售数字化移动BI':    '零售数字化移动BI',
    '让企业关键经营数据尽在"掌"握':    '让企业关键经营数据尽在"掌"握',
    '全渠道营销与私域流量管理':    '全渠道营销与私域流量管理',
    '全渠道统一平台管理   保障各渠道无缝衔接协同':    '全渠道统一平台管理   保障各渠道无缝衔接协同',
    '联系商务合作':    '联系商务合作',

    'PAAS快速开发平台工具': 'PAAS快速开发平台工具',
    '魔元术PAAS快速开发平台工具，结合魔元术19年零售行业经验沉淀，沉淀了上千种业务逻辑、将行业通用功能模块颗粒化、结合客户发展的策略和解决方案，以拖拉拽的方式，分步快速开发实现，让新零售系统更贴近业务，实现全价值链数据一体化，减少了传统系统的大量接口，数据不一致性，同时实现数据的实时性和整合性，实现分析与业务系统共存。':'魔元术PAAS快速开发平台工具，结合魔元术19年零售行业经验沉淀，沉淀了上千种业务逻辑、将行业通用功能模块颗粒化、结合客户发展的策略和解决方案，以拖拉拽的方式，分步快速开发实现，让新零售系统更贴近业务，实现全价值链数据一体化，减少了传统系统的大量接口，数据不一致性，同时实现数据的实时性和整合性，实现分析与业务系统共存。',
    '超过600个参数开发': '超过600个参数开发',
    '实现需求90%以上':    '实现需求90%以上',
    '10倍开发效率':    '10倍开发效率',

    '产品优势':  '产品优势',
    '开发效率高':    '开发效率高',
    '比传统定制开发效率快10倍左右，可省去程序前端页面、后台逻辑编写、程序集成等环节':    '比传统定制开发效率快10倍左右，可省去程序前端页面、后台逻辑编写、程序集成等环节',
    '使用门槛低':    '使用门槛低',
    '无需浪费专业开发人员成本，只需配置开发的实施顾问即可操作':    '无需浪费专业开发人员成本，只需配置开发的实施顾问即可操作',
    '满足客户个性化要求':    '满足客户个性化要求',
    '该工具可满足客户80%左右的需求，剩余20%个性化定制，充分满足客户业务流程的多样化要求':    '该工具可满足客户80%左右的需求，剩余20%个性化定制，充分满足客户业务流程的多样化要求',
    '重塑性强':    '重塑性强',
    '功能搭建自由灵活，保证满足零售企业随着市场不断的变化而变化':    '功能搭建自由灵活，保证满足零售企业随着市场不断的变化而变化',

    '解决行业发展痛点': '解决行业发展痛点',
    '多家软件组装拼接、数据不能互通，形成数据孤岛':    '多家软件组装拼接、数据不能互通，形成数据孤岛',
    '没有货通人通店通，数据价值差':    '没有货通人通店通，数据价值差',
    '可扩展性差，开发速度慢(基本都是定制型)':    '可扩展性差，开发速度慢(基本都是定制型)',
    '查询数据不实时(移动化水平低)':    '查询数据不实时(移动化水平低)',

    '新零售一体化系统': '新零售一体化系统',
    '实现功能的集合，真正做到一体化，系统中包含了MDM、DRP、HR、POS、OA、WMS、CRM、FICO、EB以及一些传统接口功能，功能全面，解决了目前市场上其他家软件平台通过接口集成众多软件功能的问题。':'实现功能的集合，真正做到一体化，系统中包含了MDM、DRP、HR、POS、OA、WMS、CRM、FICO、EB以及一些传统接口功能，功能全面，解决了目前市场上其他家软件平台通过接口集成众多软件功能的问题。',
    '系统支持多语言，多财务组织、多HR组织结构图、多货币，较市面上软件逻辑处理更复杂。':'系统支持多语言，多财务组织、多HR组织结构图、多货币，较市面上软件逻辑处理更复杂。',
    '新零售一体化系统': '新零售一体化系统',
    '技术、产品、服务全面升级，重构新零售':    '技术、产品、服务全面升级，重构新零售',
    '痛点':    '痛点',
    '成效':    '成效',
    '开发速度慢':    '开发速度慢',
    '投入高':    '投入高',
    '管理软件众多':    '管理软件众多',
    '数据不互通':    '数据不互通',
    '移动化水平低':    '移动化水平低',
    '操作复杂':    '操作复杂',
    '可拓展性差':    '可拓展性差',
    '10倍开发效率':    '10倍开发效率',
    '节约50%前期投入':    '节约50%前期投入',
    '功能一体化':    '功能一体化',
    '数据互通':    '数据互通',
    '移动化水平高':    '移动化水平高',
    '三步并一步，操作简单':    '三步并一步，操作简单',
    '支持嵌套二次开发，满足企业成长需求':    '支持嵌套二次开发，满足企业成长需求',
    '助企业真正实现一体化管理':    '助企业真正实现一体化管理',

    '魔元术新零售一体化系统-DRP':'魔元术新零售一体化系统-DRP',
    '行业定制的门店巡店(IIS)、神秘访客(MSP)及日常管理评分，是在10多年零售行业及上百家零售业态中提炼出的管理规范。':    '行业定制的门店巡店(IIS)、神秘访客(MSP)及日常管理评分，是在10多年零售行业及上百家零售业态中提炼出的管理规范。',
    '门店支持PC端和手机端，结合系统软件及移动设备，全方面的提示传统管理模式，做到事半功倍。':    '门店支持PC端和手机端，结合系统软件及移动设备，全方面的提示传统管理模式，做到事半功倍。',
    '可多个二维报表展示在一个页面上。':    '可多个二维报表展示在一个页面上。',
    '魔元术新零售一体化系统-EB':    '魔元术新零售一体化系统-EB',
    'EB模块集合了电商、正向O2O,反向O2O，云仓等业务，是一整套 OMS 的订单中台，并且与POS前台紧密结合，实现库存共享和最佳发货路由。':    'EB模块集合了电商、正向O2O,反向O2O，云仓等业务，是一整套 OMS 的订单中台，并且与POS前台紧密结合，实现库存共享和最佳发货路由。',
    '一体化系统能更加保障库存数据的实效性。':    '一体化系统能更加保障库存数据的实效性。',
    '魔元术新零售一体化系统-EB':    '魔元术新零售一体化系统-EB',
    '支持各平台通过API获取到':    '支持各平台通过API获取到',
    'EB系统订单池':    'EB系统订单池',
    '人':    '人',
    '公司':    '公司',
    '货':    '货',
    '店':    '店',
    '魔元术新零售一体化系统-MDM':    '魔元术新零售一体化系统-MDM',
    '支持多品牌事业部、多品牌商品运营、多品牌渠道管理、多品牌核算等':    '支持多品牌事业部、多品牌商品运营、多品牌渠道管理、多品牌核算等',
    '系统拥有400多个可设置开关型功能参数，灵活调整，打通人、货、店、公司，实现参数化，可自由配置。':    '系统拥有400多个可设置开关型功能参数，灵活调整，打通人、货、店、公司，实现参数化，可自由配置。',
    '魔元术新零售一体化系统-WMS':    '魔元术新零售一体化系统-WMS',
    '支持仓库锁定商品可下单数量、装车单纳入WMS管理、差异仓库管理、多货主多建筑体多类型多库区化库存共享管理、用户自定义组合拣货配置模式。':    '支持仓库锁定商品可下单数量、装车单纳入WMS管理、差异仓库管理、多货主多建筑体多类型多库区化库存共享管理、用户自定义组合拣货配置模式。',
    '魔元术新零售一体化系统-CRM':    '魔元术新零售一体化系统-CRM',
    '拥有用户画像管理、专属导购功能，丰富的自定义活动入会员积分兑换现金，现金翻倍；自定义发券规则。':    '拥有用户画像管理、专属导购功能，丰富的自定义活动入会员积分兑换现金，现金翻倍；自定义发券规则。',
    '魔元术新零售一体化系统-FCIO':    '魔元术新零售一体化系统-FCIO',
    '支持多财务组织、多货币、多种成本管理模式。':    '支持多财务组织、多货币、多种成本管理模式。',
    '财务应收应付与前端业务紧密结合，深入业务的每一个环节，供应商、加盟商的对账，在线协调平台，实现内外财务数据共享。':    '财务应收应付与前端业务紧密结合，深入业务的每一个环节，供应商、加盟商的对账，在线协调平台，实现内外财务数据共享。',
    '集成税务接口,通过商场开票模块可直接上传发票信息。':    '集成税务接口,通过商场开票模块可直接上传发票信息。',
    '解决了零售行业多小、杂的困难':    '解决了零售行业多小、杂的困难',
    '打通了B2B和B2C业务流程':    '打通了B2B和B2C业务流程',
    '将DRP和WMS无缝对接':    '将DRP和WMS无缝对接',
    '去除了传统WMS的接口':    '去除了传统WMS的接口',
    'Capillary(客提利)CRM':    'Capillary(客提利)CRM',
    '达摩CRM':    '达摩CRM',
    'HCR(慧辰资讯)CRM':    'HCR(慧辰资讯)CRM',
    '微软CRM':    '微软CRM',
    '数云CRM':    '数云CRM',
    '微盟CRM':    '微盟CRM',
    '枭荣CRM':    '枭荣CRM',

    '新零售八大亮点':   '新零售八大亮点',
    '全渠道':    '全渠道',
    '支持门店、电商、第三方平台等多种销售渠道，线上线下全线贯通':    '支持门店、电商、第三方平台等多种销售渠道，线上线下全线贯通',
    '一体化':    '一体化',
    '一个数据中心，一体化管理，打通线上和线下，跨店跨区统一运营':    '一个数据中心，一体化管理，打通线上和线下，跨店跨区统一运营',
    '大数据':    '大数据',
    '所有门店、所有渠道数据实时同步，让企业掌握一手数据，实现大数据分析':    '所有门店、所有渠道数据实时同步，让企业掌握一手数据，实现大数据分析',
    '强管控':    '强管控',
    '数据归集至总部，集团式企业管理，实现对店、对人、对用户的全面掌控':    '数据归集至总部，集团式企业管理，实现对店、对人、对用户的全面掌控',
    '高效管理':    '高效管理',
    '智能补货、OMS自动分单、多方配送等智能化管理方式，让管理更高效':    '智能补货、OMS自动分单、多方配送等智能化管理方式，让管理更高效',
    '精准营销':    '精准营销',
    '三合一会员卡、二维码营销、LBS定位推荐等精准营销方式，快速吸粉':    '三合一会员卡、二维码营销、LBS定位推荐等精准营销方式，快速吸粉',
    '智慧销售':    '智慧销售',
    '不同门店销售不同商品，每个门店都能独立打造爆款，线上线下同步销售':    '不同门店销售不同商品，每个门店都能独立打造爆款，线上线下同步销售',
    '灵活运营':    '灵活运营',
    '总部可统一运营，门店也能自己开展促销活动，支持送货上门、自提等多种配送方式':    '总部可统一运营，门店也能自己开展促销活动，支持送货上门、自提等多种配送方式',

    '零售四宝': '零售四宝',
    '安全中心':    '安全中心',
    '性能中心':    '性能中心',
    '准确中心':    '准确中心',

    'PAAS快速开发平台工具':'PAAS快速开发平台工具',
    '魔元术PAAS快速开发平台工具，结合魔元术19年零售行业经验沉淀，沉淀了上千种业务逻辑、将行业通用功能模块颗粒化、结合客户发展的策略和解决方案，以拖拉拽的方式，分步快速开发实现，让新零售系统更贴近业务，实现全价值链数据一体化，减少了传统系统的大量接口，数据不一致性，同时实现数据的实时性和整合性，实现分析与业务系统共存。':'魔元术PAAS快速开发平台工具，结合魔元术19年零售行业经验沉淀，沉淀了上千种业务逻辑、将行业通用功能模块颗粒化、结合客户发展的策略和解决方案，以拖拉拽的方式，分步快速开发实现，让新零售系统更贴近业务，实现全价值链数据一体化，减少了传统系统的大量接口，数据不一致性，同时实现数据的实时性和整合性，实现分析与业务系统共存。',
    '五大优势':    '五大优势',
    '全移动化场景':    '全移动化场景',
    '不受收银台PC硬件束缚，支持iOS和Android移动设备':    '不受收银台PC硬件束缚，支持iOS和Android移动设备',
    '多维度分析':    '多维度分析',
    '多维度指标，深层次挖掘数据价值，洞悉问题所在':    '多维度指标，深层次挖掘数据价值，洞悉问题所在',
    '智能预警':    '智能预警',
    '多种AI预警功能，帮助管理层实时把控门店经营风险':    '多种AI预警功能，帮助管理层实时把控门店经营风险',
    '精细化运营':    '精细化运营',
    '从消费者、购物体验、导购效率等各方面提升门店的数字化运营能力':    '从消费者、购物体验、导购效率等各方面提升门店的数字化运营能力',
    '功能一体化':    '功能一体化',
    '打通数据多端同步，线上线下业务全覆盖，后台统一管理':    '打通数据多端同步，线上线下业务全覆盖，后台统一管理',

    '全方位数字化运营优势':  '全方位数字化运营优势',
    '快速化':    '快速化',
    '不受收银台PC硬件束缚，支持iOS和Android移动设备':    '不受收银台PC硬件束缚，支持iOS和Android移动设备',
    '特有的去中心化计算，最大程度的利用了本地计算富余的计算能，节约了成本':    '特有的去中心化计算，最大程度的利用了本地计算富余的计算能，节约了成本',
    '一体化':    '一体化',
    '整合五种形式POS，行业内独一性':    '整合五种形式POS，行业内独一性',
    '扩展性强、支持多种接口':    '扩展性强、支持多种接口',
    '支持多语言、多币种':    '支持多语言、多币种',
    '支持多种收银模式，如现金、支付宝、微信支付等':    '支持多种收银模式，如现金、支付宝、微信支付等',
    '支持离线模式，安卓、苹果、小程序等多种模式':    '支持离线模式，安卓、苹果、小程序等多种模式',
    '移动化':    '移动化',
    '在线POS支持手机支付、小程序扫码购进行营销下单':    '在线POS支持手机支付、小程序扫码购进行营销下单',
    '接通微信支付，扫码购直接联网后台':    '接通微信支付，扫码购直接联网后台',
    '便捷化':    '便捷化',
    '安装便捷':    '安装便捷',
    '操作便捷':    '操作便捷',
    '操作页面秉承“三步并一步”理念，简单明了，操作简捷':    '操作页面秉承“三步并一步”理念，简单明了，操作简捷',
    '参数自定义':    '参数自定义',
    '千店千面、操作页面自定义':    '千店千面、操作页面自定义',
    '业务化':    '业务化',
    '智能营销推荐':    '智能营销推荐',
    '自动计算最优促销，给客户推荐最优促销，提供最佳的购物体验':    '自动计算最优促销，给客户推荐最优促销，提供最佳的购物体验',
    '商场结算管理':    '商场结算管理',
    '商品及库存管控':    '商品及库存管控',
    '库存管控精细化（负库存管控严谨）':    '库存管控精细化（负库存管控严谨）',
    '场景化':    '场景化',
    '行业首创最优促销，可实现多种促销和卡券重合':    '行业首创最优促销，可实现多种促销和卡券重合',
    '促销功能多样化且具有创新性，支持市面上99%促销形式，活动和活动之间可叠加':    '促销功能多样化且具有创新性，支持市面上99%促销形式，活动和活动之间可叠加',

    '全面提升数字化门店运营能力':  '全面提升数字化门店运营能力',
    '家门店':    '家门店',
    '共同的选择':    '共同的选择',

    '魔数智屏——数据可视化大屏展示': '魔数智屏——数据可视化大屏展示',
    '与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。': '与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。',
    '魔数智屏': '魔数智屏',
    '自2018年7月成立起，魔元术便开始对数据可视化大屏产品——魔数智屏做深入的研发，经过安踏集团、波司登集团、太平鸟集团等10多个大型客户的沉淀，以及一些中小型企业的打磨，结合魔元术研发团队十六年以上的行业信息化研发经验，千磨百炼，打造出行业适用性强、低代码敏捷化开发、拖拽式、多端（PC端、大屏端、移动端）互联控制、科技感十足的魔元术数据可视化大屏产品——魔数智屏。':  '自2018年7月成立起，魔元术便开始对数据可视化大屏产品——魔数智屏做深入的研发，经过安踏集团、波司登集团、太平鸟集团等10多个大型客户的沉淀，以及一些中小型企业的打磨，结合魔元术研发团队十六年以上的行业信息化研发经验，千磨百炼，打造出行业适用性强、低代码敏捷化开发、拖拽式、多端（PC端、大屏端、移动端）互联控制、科技感十足的魔元术数据可视化大屏产品——魔数智屏。',
    '魔数智屏与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。': '魔数智屏与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。',
    '行业痛点': '行业痛点',
    '数据展示风格单一':    '数据展示风格单一',
    '产品体验不佳':    '产品体验不佳',
    '视觉设计和交互能力弱':    '视觉设计和交互能力弱',
    '数据反馈不及时':    '数据反馈不及时',
    '数据信息不互通':    '数据信息不互通',
    '决策管理无数据依据':    '决策管理无数据依据',
    '魔数智屏——数据可视化配置工具':    '魔数智屏——数据可视化配置工具',
    '作为数据可视化配置工具，可以让更多的人看到数据可视化的魅力，帮助非专业的工程师通过图形化的界面轻松搭建专业水准的可视化应用，满足用户会议展览、业务监控、风险预警、地理信息分析等多种业务的展示需求。': '作为数据可视化配置工具，可以让更多的人看到数据可视化的魅力，帮助非专业的工程师通过图形化的界面轻松搭建专业水准的可视化应用，满足用户会议展览、业务监控、风险预警、地理信息分析等多种业务的展示需求。',
    '魔元术根据“三最”原则——企业最关键、领导最关心、业务最贴近，定制了上百种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。以多层级的数据大屏、看板为载体，梳理数据流程、整合企业中的重要指标数据，形成管理的“闭环”，让数据在大屏、在企业流程中“转”起来！': '魔元术根据“三最”原则——企业最关键、领导最关心、业务最贴近，定制了上百种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。以多层级的数据大屏、看板为载体，梳理数据流程、整合企业中的重要指标数据，形成管理的“闭环”，让数据在大屏、在企业流程中“转”起来！',
    '1.丰富的数据可视化编辑工具': '1.丰富的数据可视化编辑工具',
    '通过丰富的组件库和模板库，以及智能化的辅助手段，极大的降低非专业开发人员的搭建门槛。':    '通过丰富的组件库和模板库，以及智能化的辅助手段，极大的降低非专业开发人员的搭建门槛。',
    '可视化拖拽界面，强大的编辑功能':    '可视化拖拽界面，强大的编辑功能',
    ' 提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用':    ' 提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用',
    '丰富的模板库':    '丰富的模板库',
    '多种场景模版，简单修改即可快速投入使用。即便没有设计师，也可搭建专业大屏':    '多种场景模版，简单修改即可快速投入使用。即便没有设计师，也可搭建专业大屏',
    '丰富的组件库':    '丰富的组件库',
    '提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用':    '提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用',
    '支持自定义组件':    '支持自定义组件',
    '提供开发者工具可以基于一定的组件规范，快速开发出自定义组件满足个性化需求。':    '提供开发者工具可以基于一定的组件规范，快速开发出自定义组件满足个性化需求。',
    '自定义组件存放在用户自己的组件库中，可供用户自由复用。':    '自定义组件存放在用户自己的组件库中，可供用户自由复用。',
    '2.专业的地理信息可视化':    '2.专业的地理信息可视化',
    '通过组件开发工具、编辑器等模块，帮助专业可视化开发人员灵活的进行组件开发和交互配置。':    '通过组件开发工具、编辑器等模块，帮助专业可视化开发人员灵活的进行组件开发和交互配置。',
    '专业地理信息组件':    '专业地理信息组件',
    '支持绘制地理轨迹、地理飞线、热力分布、3D地球等效果，可以一站式的完成空间地理数据相关的可视分析。':    '支持绘制地理轨迹、地理飞线、热力分布、3D地球等效果，可以一站式的完成空间地理数据相关的可视分析。',
    '低代码交互功能开发':    '低代码交互功能开发',
    '通过蓝图编辑器来实现组件间的交互配置，复杂的交互逻辑可视化呈现，更易于维护。':    '通过蓝图编辑器来实现组件间的交互配置，复杂的交互逻辑可视化呈现，更易于维护。',
    '3.大屏与移动BI互为一体，共同联动控制——移动BI配置工具': '3.大屏与移动BI互为一体，共同联动控制——移动BI配置工具',
    '自助BI报表分析和制作可视化数据大屏的强大工具，通过拖拽图表组件可实现 5 分钟搭建数据可视化页面，组件丰富。通过可视化图表及强大的交互分析能力，为企业快速构建敏捷智能数据分析平台。':  '自助BI报表分析和制作可视化数据大屏的强大工具，通过拖拽图表组件可实现 5 分钟搭建数据可视化页面，组件丰富。通过可视化图表及强大的交互分析能力，为企业快速构建敏捷智能数据分析平台。',
    '优势':'优势',
    '新一代敏捷BI 让决策不再等数据': '新一代敏捷BI 让决策不再等数据',
    '丰富的功能和可视化图表模板，快速生成报表，满足企业数据分析和可视化展示需求':'丰富的功能和可视化图表模板，快速生成报表，满足企业数据分析和可视化展示需求',
    '简单好用易上手 快速完成数据分析报告':    '简单好用易上手 快速完成数据分析报告',
    '产品使用门槛低，与数据源打通，拖拽点选、快速响应、即时生效、灵活调整，简单易上手':    '产品使用门槛低，与数据源打通，拖拽点选、快速响应、即时生效、灵活调整，简单易上手',
    '极致美学设计、搭配高效的可视化技术':    '极致美学设计、搭配高效的可视化技术',
    '极致的数据美学，结合魔元术可视化技术，生动友好的呈现数据，同时提供丰富的报表模板，快速搭建设计师水准的数据报表':    '极致的数据美学，结合魔元术可视化技术，生动友好的呈现数据，同时提供丰富的报表模板，快速搭建设计师水准的数据报表',
    '灵活多端，一次配置多端查看':    '灵活多端，一次配置多端查看',
    '集成微信或企业微信，手机、PC、大屏等多种设备上自适应展示，随时随地查看分析可视化数据':    '集成微信或企业微信，手机、PC、大屏等多种设备上自适应展示，随时随地查看分析可视化数据',
    'BI报表与大屏一体化':    'BI报表与大屏一体化',
    '集BI报表与大屏功能于一体，满足客户不同场景的可视化需求':    '集BI报表与大屏功能于一体，满足客户不同场景的可视化需求',
    '移动端完美适配':    '移动端完美适配',
    '布局自动适配移动端界面，随时随地查看分析可视化数据':    '布局自动适配移动端界面，随时随地查看分析可视化数据',
    '数据产品化  让数据分析思路可沉淀':    '数据产品化  让数据分析思路可沉淀',
    '展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析':    '展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析',
    'PC端所有大屏配置功能，移动端可完美复用，双端联控':    'PC端所有大屏配置功能，移动端可完美复用，双端联控',
    'PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。':    'PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。',
    '产品特征': '产品特征',
    '专业级大数据可视化':    '专业级大数据可视化',
    '专精于地理信息与业务数据融合的可视化，提供丰富的行业模版和组件，支持自定义组件':    '专精于地理信息与业务数据融合的可视化，提供丰富的行业模版和组件，支持自定义组件',
    '实时高效的数据':    '实时高效的数据',
    '支持从静态数据、CSV 文件、公网数据库，腾讯云数据库和 API 等多种数据源接入数据，通过配置可以动态实时更新':    '支持从静态数据、CSV 文件、公网数据库，腾讯云数据库和 API 等多种数据源接入数据，通过配置可以动态实时更新',
    '拖拽式设计，在线自由布局设计':    '拖拽式设计，在线自由布局设计',
    '拖拽即可完成样式和数据配置，无需编程就能轻松搭建数据大屏':    '拖拽即可完成样式和数据配置，无需编程就能轻松搭建数据大屏',
    '炫酷的3D展现与动画特效':    '炫酷的3D展现与动画特效',
    '具有极强的视觉体验和冲击力，提供丰富3D图表、轮播动画、自动翻页、表格滚动等动画特效，通过简单拖拽方式即可实现3D动画、自动播放等多种特效，科技感十足，让大屏展示效果更具炫酷性。':    '具有极强的视觉体验和冲击力，提供丰富3D图表、轮播动画、自动翻页、表格滚动等动画特效，通过简单拖拽方式即可实现3D动画、自动播放等多种特效，科技感十足，让大屏展示效果更具炫酷性。',
    '移动端完美适配':    '移动端完美适配',
    '布局自动适配移动端界面，随时随地查看分析可视化数据':    '布局自动适配移动端界面，随时随地查看分析可视化数据',
    '灵活部署和推送':    '灵活部署和推送',
    '适配非常规拼接大屏，支持自定义推送，支持本地部署':    '适配非常规拼接大屏，支持自定义推送，支持本地部署',
    '案例一':'案例一',
    '案例二':    '案例二',
    '案例三':    '案例三',
    '魔数智屏的优势':    '魔数智屏的优势',
    '更强的图表交互能力':    '更强的图表交互能力',
    '颜色预警、高亮联动、图表最大化、图表任意联动等多项功能，提供更好的图表交互能力。':    '颜色预警、高亮联动、图表最大化、图表任意联动等多项功能，提供更好的图表交互能力。',
    '更强的探索式分析能力':    '更强的探索式分析能力',
    '通过报表间智能钻取与多维动态分析，真正实现探索式分析，从问题到原因，通过鼠标即可搞定！':    '通过报表间智能钻取与多维动态分析，真正实现探索式分析，从问题到原因，通过鼠标即可搞定！',
    '丰富精美的行业模板':    '丰富精美的行业模板',
    '我们定制了几十种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。如果已有的模板无法满足你的需要，也可以联系我们做定制开发。':    '我们定制了几十种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。如果已有的模板无法满足你的需要，也可以联系我们做定制开发。',
    '强大的地理信息可视化':    '强大的地理信息可视化',
    '在地理信息可视化方面你可以创建世界地图、全国地图和全国344个区县的地图，可以完成多层级视图(世界级视图、国家视图、省级视图等等)的切换操作，包括视图下钻与返回，还具有海量点图、热力图、动态连线等（此特性为echarts能力）。': '在地理信息可视化方面你可以创建世界地图、全国地图和全国344个区县的地图，可以完成多层级视图(世界级视图、国家视图、省级视图等等)的切换操作，包括视图下钻与返回，还具有海量点图、热力图、动态连线等（此特性为echarts能力）。',
    '灵活的适配和部署':'灵活的适配和部署',
    '数据展现方案以网页形式发布，适配chrome、Edge等主流浏览器，还可以使用本地应用程序进行内嵌；移动端可以集成到微信、企业微信、钉钉中；通过定制调整可以支持适配各种尺寸、各种不规则形状的屏幕。': '数据展现方案以网页形式发布，适配chrome、Edge等主流浏览器，还可以使用本地应用程序进行内嵌；移动端可以集成到微信、企业微信、钉钉中；通过定制调整可以支持适配各种尺寸、各种不规则形状的屏幕。',
    '行业应用及应用场景':'行业应用及应用场景',
    '1.实时汇报':    '1.实时汇报',
    '交互式实时数据可视化大屏，实时监测企业数据，洞悉运营增长，助力智能高效决策。多源适配，可视化建模，配合强大灵活的数据分析能力，轻松实现秒级数据响应，实时发现异常。数据监控，安全预警，异常发现，多种实时场景，灵活适配：': '交互式实时数据可视化大屏，实时监测企业数据，洞悉运营增长，助力智能高效决策。多源适配，可视化建模，配合强大灵活的数据分析能力，轻松实现秒级数据响应，实时发现异常。数据监控，安全预警，异常发现，多种实时场景，灵活适配：',
    '（1）多方位、多角度、全景展现企业的各项指标': '（1）多方位、多角度、全景展现企业的各项指标',
    '（2）数据实时监控，企业动态一目了然':    '（2）数据实时监控，企业动态一目了然',
    '（3）个性化定制，布局灵活，样式丰富':    '（3）个性化定制，布局灵活，样式丰富',
    '2.对内监管、激励': '2.对内监管、激励',
    '数据可视化与业务紧密相连，可以让员工轻松的了解到工作的进度，让领导分析其业务表现，同时管理者可以根据此来改变整体业务的方式和方向，帮助分析绩效以及预测未来的绩效。通过分析，能够掌握到企业的动态，做到趋利避害：': '数据可视化与业务紧密相连，可以让员工轻松的了解到工作的进度，让领导分析其业务表现，同时管理者可以根据此来改变整体业务的方式和方向，帮助分析绩效以及预测未来的绩效。通过分析，能够掌握到企业的动态，做到趋利避害：',
    '（1）目标展示，鼓励业务人员完成目标':  '（1）目标展示，鼓励业务人员完成目标',
    '（2）透明激励、横向对比竞争':    '（2）透明激励、横向对比竞争',
    '（3）分析复盘，根据实际完成情况，提高管理者决策正确性':    '（3）分析复盘，根据实际完成情况，提高管理者决策正确性',
    '3.对外宣传':    '3.对外宣传',
    '引入“面向对象”的场景构建方法，对不同的宣传对象在满足保密要求的前提下，实现宣传内容的精准投放，以发挥企业优势、提升客户满意度，有效推动企业品牌建设：':    '引入“面向对象”的场景构建方法，对不同的宣传对象在满足保密要求的前提下，实现宣传内容的精准投放，以发挥企业优势、提升客户满意度，有效推动企业品牌建设：',
    '（1）直观掌握企业运营状态，及时获取企业数据':    '（1）直观掌握企业运营状态，及时获取企业数据',
    '（2）有效利用数据资产，充分发挥数据价值':    '（2）有效利用数据资产，充分发挥数据价值',
    '（3）一体化的数字化展示窗口，及时、高效的进行全域信息的数字化展示':    '（3）一体化的数字化展示窗口，及时、高效的进行全域信息的数字化展示',
    'BI报表与大屏一体化':    'BI报表与大屏一体化',
    '集BI报表与大屏功能于一体，满足客户不同场景的可视化需求':    '集BI报表与大屏功能于一体，满足客户不同场景的可视化需求',
    '移动端完美适配':    '移动端完美适配',
    '布局自动适配移动端界面，随时随地查看分析可视化数据':    '布局自动适配移动端界面，随时随地查看分析可视化数据',
    '数据产品化  让数据分析思路可沉淀':    '数据产品化  让数据分析思路可沉淀',
    '展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析':    '展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析',
    'PC端所有大屏配置功能，移动端可完美复用，双端联控':    'PC端所有大屏配置功能，移动端可完美复用，双端联控',
    'PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。':    'PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。',
    '应用行业案例':    '应用行业案例',
    '客户案例一：波司登集团':  '客户案例一：波司登集团',
    '波司登集团利用魔数智屏，基于自有的信息化系统，打造出了数据中台监控、供应链监控、产品业态分析、定制业务监控、O2O业务销售监控、车间实时监控、工单处理监控等。':    '波司登集团利用魔数智屏，基于自有的信息化系统，打造出了数据中台监控、供应链监控、产品业态分析、定制业务监控、O2O业务销售监控、车间实时监控、工单处理监控等。',
    '波司登店态数据中台大屏':    '波司登店态数据中台大屏',
    '同一个大屏在不同店态的旗舰店的显示屏上，展示各自的营销动态，辅助店员及店长实时查看货品信息及销售状况，更具直观性及实时性。':    '同一个大屏在不同店态的旗舰店的显示屏上，展示各自的营销动态，辅助店员及店长实时查看货品信息及销售状况，更具直观性及实时性。',
    '波司登数据中台大屏':    '波司登数据中台大屏',
    '主要用于管理层查询集团总体数据情况，支持多维度多角色数据查询，为管理层做决策起到数据支持性作用。':    '主要用于管理层查询集团总体数据情况，支持多维度多角色数据查询，为管理层做决策起到数据支持性作用。',
    '波司登O2O业务业务监控看板':    '波司登O2O业务业务监控看板',
    '整体通过数据与地图的集合从不同的展示视角为用户展示不同区域的数据情况，数据动态展示与地图紧密结合，实现了对O2O业务全方位实时监控，O2O业务进度一目了然，帮助业务部门实时跟进，促进用户对企业的决策与效率。':  '整体通过数据与地图的集合从不同的展示视角为用户展示不同区域的数据情况，数据动态展示与地图紧密结合，实现了对O2O业务全方位实时监控，O2O业务进度一目了然，帮助业务部门实时跟进，促进用户对企业的决策与效率。',
    '注：以上所有数据均为模拟数据':  '注：以上所有数据均为模拟数据',
    '客户案例二：太平鸟集团':    '客户案例二：太平鸟集团',
    '太平鸟2020年起使用魔数智屏产品，在企业形象墙上展示实时销售情况。大屏中对外销售数据经过脱敏处理，在有限的空间中表达庞大的信息量，精炼数据的同时保证数据的完整性，完美契合用户的菱形巨屏。当外部客户参观、领导视察时，菱形大屏的震撼效果彰显了公司的实力。': '太平鸟2020年起使用魔数智屏产品，在企业形象墙上展示实时销售情况。大屏中对外销售数据经过脱敏处理，在有限的空间中表达庞大的信息量，精炼数据的同时保证数据的完整性，完美契合用户的菱形巨屏。当外部客户参观、领导视察时，菱形大屏的震撼效果彰显了公司的实力。',
    '注：所有数据均为模拟数据':  '注：所有数据均为模拟数据',
    '客户案例三：安踏集团':    '客户案例三：安踏集团',
    '2019年起服务于安踏新零售大数据的可视化系列页面，基于魔元术自有新零售数据中台产品，将安踏的销售数据、会员数据、区域数据等进行监控查看。同时适配PC端操作习惯，提供用户日、周、月、年等条件筛选，完美集成展示大屏和PC看板各自优势，全方位、多层次地展示零售数据，有效提高了对数据管理的宏观掌控、实时监控，为安踏的后续性能、数据分析打好了良好的基础。': '2019年起服务于安踏新零售大数据的可视化系列页面，基于魔元术自有新零售数据中台产品，将安踏的销售数据、会员数据、区域数据等进行监控查看。同时适配PC端操作习惯，提供用户日、周、月、年等条件筛选，完美集成展示大屏和PC看板各自优势，全方位、多层次地展示零售数据，有效提高了对数据管理的宏观掌控、实时监控，为安踏的后续性能、数据分析打好了良好的基础。',
    '注：以上所有数据均为模拟数据':  '注：以上所有数据均为模拟数据',
    '客户案例四：SUNTOP（制造业）':    '客户案例四：SUNTOP（制造业）',
    'SUNTOP使用魔数智屏，根据自身硬件设备的传感器和监控，将数据汇总到魔数智屏后端，利用多屏轮播功能，直观的将总览信息、设备硬件信息、客流监控信息等数据整合在一起，还能顾及到数据的实时性，助力制造型企业“数字化转型”，提升管理体验。':  'SUNTOP使用魔数智屏，根据自身硬件设备的传感器和监控，将数据汇总到魔数智屏后端，利用多屏轮播功能，直观的将总览信息、设备硬件信息、客流监控信息等数据整合在一起，还能顾及到数据的实时性，助力制造型企业“数字化转型”，提升管理体验。',
    '注：所有数据均为模拟数据':  '注：所有数据均为模拟数据',
    '持续创新赋能 助力品牌成长':    '持续创新赋能 助力品牌成长',
    '十九年的技术和服务沉淀 成就知名品牌坚定选择':    '十九年的技术和服务沉淀 成就知名品牌坚定选择',
    '产品优势':    '产品优势',
    '提高决策效率':    '提高决策效率',
    '实时全面的数据交互分析，一目了然的业务数据展示，高效的团队分享协作':    '实时全面的数据交互分析，一目了然的业务数据展示，高效的团队分享协作',
    '丰富的可视化组件':    '丰富的可视化组件',
    '提供丰富的统计分析图表组件、Web组态图、地图等，以及行业特色化的场景仪表盘，满足各类数据展现需求':    '提供丰富的统计分析图表组件、Web组态图、地图等，以及行业特色化的场景仪表盘，满足各类数据展现需求',
    '丰富的应用场景':    '丰富的应用场景',
    '驾驶舱、宇宙、机场航班等场景，支持发布到多场景的数据大屏上进行可视化集中管控':    '驾驶舱、宇宙、机场航班等场景，支持发布到多场景的数据大屏上进行可视化集中管控',
    '轻松认知数据':    '轻松认知数据',
    '数据双向互动，提供给用户对大数据可视化需求的无限助力与可能，让非专业用户也可以轻松自如地应对各项数据':    '数据双向互动，提供给用户对大数据可视化需求的无限助力与可能，让非专业用户也可以轻松自如地应对各项数据',
    '助力企业决策':    '助力企业决策',
    '利用直观灵活多样的图表展示为企业提供业务驱动力，为企业提供决策支持。':    '利用直观灵活多样的图表展示为企业提供业务驱动力，为企业提供决策支持。',
    '多种统计方式':    '多种统计方式',
    '求和、平均值、最大值、最小值、计数、不重复计数等多种统计方式，并可以对统计结果做数值格式处理':    '求和、平均值、最大值、最小值、计数、不重复计数等多种统计方式，并可以对统计结果做数值格式处理',
    '共享数据便捷':    '共享数据便捷',
    '台式机、笔记本电脑的本地单一接口':    '台式机、笔记本电脑的本地单一接口',
    '响应快速':    '响应快速',
    '支持动态局部刷新，秒级响应，各项操作流畅，即使数量巨大，用户也不必担心卡顿':    '支持动态局部刷新，秒级响应，各项操作流畅，即使数量巨大，用户也不必担心卡顿',
    '产品特色':    '产品特色',
    '利用目前国际流行的前端UI包括canvas、Vue等':    '利用目前国际流行的前端UI包括canvas、Vue等',
    '整合MMT特有的几百种新零售指标':    '整合MMT特有的几百种新零售指标',
    '丰富的2D/3D图库展现数据动态化、图形':    '丰富的2D/3D图库展现数据动态化、图形',
    '后台利用大型数据库及相关算法进行计算':    '后台利用大型数据库及相关算法进行计算',
    '应用场景':    '应用场景',
    '战略指挥中心':    '战略指挥中心',
    '集团会议中心':    '集团会议中心',
    '实时监控中心':    '实时监控中心',
    '零售数字化移动BI':    '零售数字化移动BI',
    '实现大数据的实时性和移动化，同时支持不同维度和角色的数据报表展现，随时随地查阅经营指标，快速准确获取所需的业务数据及分析展现，及时做出运营决策，从而更好地帮助企业进行业务管控。': '实现大数据的实时性和移动化，同时支持不同维度和角色的数据报表展现，随时随地查阅经营指标，快速准确获取所需的业务数据及分析展现，及时做出运营决策，从而更好地帮助企业进行业务管控。',
    '行业创新 五种形式POS':  '行业创新 五种形式POS',
    '数据驱动业务增长':    '数据驱动业务增长',
    '数据的几何级增长':    '数据的几何级增长',
    '数据精益化运营':    '数据精益化运营',
    '各阶段关注指标':    '各阶段关注指标',
    '新兴的业务增长框架':    '新兴的业务增长框架',
    '(海盗法则AARRR)':    '(海盗法则AARRR)',
    '丰富的数据分析方法':    '丰富的数据分析方法',
    '钻取':    '钻取',
    '向上钻取得到汇总数据向下钻取查看明细数据':    '向上钻取得到汇总数据向下钻取查看明细数据',
    '联动':    '联动',
    '各个控件间相互关联，数据分析和结果更加明晰':    '各个控件间相互关联，数据分析和结果更加明晰',
    '预警':    '预警',
    '针对数据源设置预警阀值，自动向客户端推送预警消息':    '针对数据源设置预警阀值，自动向客户端推送预警消息',
    '穿透':    '穿透',
    '可基于维度值进行穿透关联分析，便于逻辑查看':    '可基于维度值进行穿透关联分析，便于逻辑查看',
    '切片':    '切片',
    '当维度值很多时，可选择其中一段重点分析':    '当维度值很多时，可选择其中一段重点分析',
    '多维度检索':    '多维度检索',
    '全方位，多角度对不同指标进行对比分析':    '全方位，多角度对不同指标进行对比分析',
    '应用价值':    '应用价值',
    '人':    '人',
    '满足不同的管理角色移动办公、数字化决策需求':    '满足不同的管理角色移动办公、数字化决策需求',
    '货':    '货',
    '商品实时查询分析，实现货品精细化管理，便于商品对门店库存把控':    '商品实时查询分析，实现货品精细化管理，便于商品对门店库存把控',
    '店':    '店',
    '实时掌握门店运营态势，便于门店标准化管理，提升门店运营能力':    '实时掌握门店运营态势，便于门店标准化管理，提升门店运营能力',
    '场':    '场',
    '实时掌握企业运营态势，提升企业运营管理和风险管控水平，增强工作预见性':    '实时掌握企业运营态势，提升企业运营管理和风险管控水平，增强工作预见性',
    '全渠道订单路由':    '全渠道订单路由',
    '打通全渠道 布局新零售':    '打通全渠道 布局新零售',
    '会员通':    '会员通',
    '商品通':    '商品通',
    '服务通':    '服务通',
    '全渠道 ':    '全渠道 ',
    '线上线下':    '线上线下',
    '一体化':    '一体化',
    '电商':    '电商',
    '实体':    '实体',
    '渠道':    '渠道',
    '货品融合':    '货品融合',
    '交易融合':    '交易融合',
    '渠道融合':    '渠道融合',
    '体验融合':    '体验融合',
    '同一盘货':    '同一盘货',
    '同一订单':    '同一订单',
    '同一渠道':    '同一渠道',
    '同一支付':    '同一支付',
    '货品通':    '货品通',
    '订单通':    '订单通',
    '终端通':    '终端通',
    '财务通':    '财务通',
    '线上线下一盘货，统一管理货品和库存':    '线上线下一盘货，统一管理货品和库存',
    '业务管理互融一体':    '业务管理互融一体',
    '跨平台+多店铺':    '跨平台+多店铺',
    '全渠道互通':    '全渠道互通',
    '降解库存 提升销售':    '降解库存 提升销售',
    '统一微信生态账号体系':    '统一微信生态账号体系',
    '全渠道多触点获客转化留存':    '全渠道多触点获客转化留存',
    '加客户至企微':    '加客户至企微',
    '获客留存':    '获客留存',
    '减少流失':    '减少流失',
    '客户维护':    '客户维护',
    '客户标签':    '客户标签',
    '一对一服务':    '一对一服务',
    '触达引流':    '触达引流',
    '用户画像':    '用户画像',
    '营销自动化':    '营销自动化',
    '批发':    '批发',
    '全渠道':    '全渠道',
    '零售':    '零售',
    '电商':    '电商',
    '用户服务':    '用户服务',
    '复购提醒':    '复购提醒',
    '流失回访':    '流失回访',
    '售后提醒':    '售后提醒',
    '沟通转化':    '沟通转化',
    '打标签':    '打标签',
    '看画像1V1':    '看画像1V1',
    '精准营销':    '精准营销',
    '精准营销':    '精准营销',
    '猜Ta喜欢':    '猜Ta喜欢',
    '消费记录':    '消费记录',
    '用户画像':    '用户画像',
    '扫码购':    '扫码购',
    '专属导购在线营销服务':    '专属导购在线营销服务',
    '门店发货':    '门店发货',
    '移动端发货':    '移动端发货',
    '确认需求及购买意愿':    '确认需求及购买意愿',
    '分享商品卡片':    '分享商品卡片',
    '支付结算':    '支付结算',
    '优惠券/优惠活动查看':    '优惠券/优惠活动查看',
    '加入购物车':    '加入购物车',
    '查看商品详情':    '查看商品详情',
    '物流追踪':    '物流追踪',
    // 客户案例
    '客户案例':  '客户案例',
    '驱动智慧零售 助力企业成长':    '驱动智慧零售 助力企业成长',
    '经典客户案例':    '经典客户案例',
    '为每一个客户提供行业性、定制化的解决方案':    '为每一个客户提供行业性、定制化的解决方案',
    '安踏':    '安踏',
    '为其提供终端POS、终端HR(考勤管理)、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMS（DST&KL）服务，服务品牌有FILA、DESCENTE、KOLON、Sprandi、小笑牛，门店数量超过10000家。将其多个品牌的管理整合在一套系统中，提升了其50%以上的录单效率，100多种业务场景的应用激发销售者参与感，提升工作人员积极性和服务价值，实现多品牌多财务核算体，报表查询效率明显提升。':   '为其提供终端POS、终端HR(考勤管理)、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMS（DST&KL）服务，服务品牌有FILA、DESCENTE、KOLON、Sprandi、小笑牛，门店数量超过10000家。将其多个品牌的管理整合在一套系统中，提升了其50%以上的录单效率，100多种业务场景的应用激发销售者参与感，提升工作人员积极性和服务价值，实现多品牌多财务核算体，报表查询效率明显提升。',
    '晨光九木杂物社': '晨光九木杂物社',
    '晨光九木杂物社是晨光集团下一家中高端文创杂货集合店，全国共有374 家（直营249 家，加盟125 家）。为其提供POS+、DRP、O2O订单路由、电商EB含OMS、移动BI等服务，彻底解决了原先系统运行不稳定、报错率高、操作繁琐等定性和程序健壮因素问题、POS结算效率提升60%以上、ERP报表查询速度控制在了5秒以内，大大提升了其报表查询效率、同时开发及交付效率提升了10倍左右。': '晨光九木杂物社是晨光集团下一家中高端文创杂货集合店，全国共有374 家（直营249 家，加盟125 家）。为其提供POS+、DRP、O2O订单路由、电商EB含OMS、移动BI等服务，彻底解决了原先系统运行不稳定、报错率高、操作繁琐等定性和程序健壮因素问题、POS结算效率提升60%以上、ERP报表查询速度控制在了5秒以内，大大提升了其报表查询效率、同时开发及交付效率提升了10倍左右。',
    '太平鸟':  '太平鸟',
    '为其提供多种形式POS、DRP、OMS、CRM、移动BI以及新零售数据中台服务，服务门店超过6000家，提升其门店录单效率60%以上，开发效率是一般工具的10倍左右。':    '为其提供多种形式POS、DRP、OMS、CRM、移动BI以及新零售数据中台服务，服务门店超过6000家，提升其门店录单效率60%以上，开发效率是一般工具的10倍左右。',
    '波司登':    '波司登',
    '为其提供业务数据中台，移动化项目外包开发服务，数据中台实现云平台、生产、电商、SAP以及HANA数据的大整理，报表开发效率是一般工具8倍，投入是SAP价格的5%。移动化实现了审批、数据化分析包括层级分析、沙盘演示等、以及开关店报店装修工程等移动化管理，我们的开发工具不仅提升了波司登集团的开发效率，还大大节约了其企业信息化的成本。': '为其提供业务数据中台，移动化项目外包开发服务，数据中台实现云平台、生产、电商、SAP以及HANA数据的大整理，报表开发效率是一般工具8倍，投入是SAP价格的5%。移动化实现了审批、数据化分析包括层级分析、沙盘演示等、以及开关店报店装修工程等移动化管理，我们的开发工具不仅提升了波司登集团的开发效率，还大大节约了其企业信息化的成本。',
    '助力上千家知名品牌零售商': '助力上千家知名品牌零售商',
    '连接品牌、商户和消费者':    '连接品牌、商户和消费者'
};
