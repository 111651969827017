<template>
<!--客户logo-->
  <div class="zj_customerLogo">
    <div class="title1 flexcc font16rem black wow bounceIn" 
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t('强大的技术实力，持续引领市场')}}</div>
    <div class="title2 flexcc font10rem black wow bounceIn" 
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t('十九年的经验积累，产品精打细磨，客户口碑相传')}}</div>
    <div class="customerLogo">
      <div class="logo flexcc wow" v-for="item in logodata" :key="item.index" :class="item.index<=6||item.index>=13?'bounceInLeft':'bounceInRight'"
        data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img :src="item.pic" />
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_customerLogo",
  data() {
    return {
      logodata: [
        {
          index: 1,
          name: "anta",
          pic: require("../assets/Images/brand/anta.png"),
        },
        {
          index: 2,
          name: "太平鸟",
          pic: require("../assets/Images/brand/peacebird.png"),
        },
        {
          index: 3,
          name: "波司登",
          pic: require("../assets/Images/brand/bsd.png"),
        },
        {
          index: 19,
          name: "晨光",
          pic: require("../assets/Images/brand/cg.png"),
        },
        {
          index: 19,
          name: "森马",
          pic: require("../assets/Images/brand/sm1.svg"),
        },
        {
          index: 16,
          name: "amersports",
          pic: require("../assets/Images/brand/amersports.png"),
        },
        {
          index: 6,
          name: "雅诗兰黛",
          pic: require("../assets/Images/brand/EsteeLauder.png"),
        },
        {
          index: 16,
          name: "海棠铺子",
          pic: require("../assets/Images/brand/htpz.png"),
        },
        {
          index: 6,
          name: "戎美",
          pic: require("../assets/Images/brand/rm1.jpg"),
        },
        //
        // {
        //   index: 8,
        //   name: "delicates",
        //   pic: require("../assets/Images/brand/delicates.png"),
        // },
        // {
        //   index: 9,
        //   name: "suunto",
        //   pic: require("../assets/Images/brand/suunto.png"),
        // },
        // {
        //   index: 17,
        //   name: "precor",
        //   pic: require("../assets/Images/brand/precor.png"),
        // },
        // {
        //   index: 18,
        //   name: "atomic",
        //   pic: require("../assets/Images/brand/atomic.png"),
        // },
        {
          index: 4,
          name: "fila",
          pic: require("../assets/Images/brand/fila.png"),
        },
        {
          index: 5,
          name: "绿叶",
          pic: require("../assets/Images/brand/ly.png"),
        },
        {
          index: 7,
          name: "木槿",
          pic: require("../assets/Images/brand/mumuso.png"),
        },
        {
          index: 8,
          name: "九木",
          pic: require("../assets/Images/brand/jm.png"),
        },
        {
          index: 10,
          name: "outdoor",
          pic: require("../assets/Images/brand/outdoor.png"),
        },
        {
          index: 11,
          name: "热风",
          pic: require("../assets/Images/brand/hotwind.png"),
        },
        {
          index: 12,
          name: "wilson",
          pic: require("../assets/Images/brand/wilson.png"),
        },
        {
          index: 13,
          name: "可隆",
          pic: require("../assets/Images/brand/kolon.png"),
        },
        {
          index: 15,
          name: "迪桑特",
          pic: require("../assets/Images/brand/descente.png"),
        },
        {
          index: 16,
          name: "balabala",
          pic: require("../assets/Images/brand/balabala.png"),
        },
        {
          index: 17,
          name: "lancome",
          pic: require("../assets/Images/brand/lancome.png"),
        },
        {
          index: 18,
          name: "TheGreenParty",
          pic: require("../assets/Images/brand/TheGreenParty.png"),
        },
        {
          index: 19,
          name: "mollyWood",
          pic: require("../assets/Images/brand/mollyWood.png"),
        },
        {
          index: 20,
          name: "sprandi",
          pic: require("../assets/Images/brand/sprandi.png"),
        },
       {
          index: 21,
          name: "arcteryx",
          pic: require("../assets/Images/brand/arcteryx.png"),
        },
        {
          index: 14,
          name: "小笑牛",
          pic: require("../assets/Images/brand/kingkow.png"),
        },
        {
          index: 23,
          name: "salomon",
          pic: require("../assets/Images/brand/salomon.png"),
        },
        {
          index: 24,
          name: "atomic",
          pic: require("../assets/Images/brand/atomic.png"),
        },
        {
          index: 25,
          name: "diamond",
          pic: require("../assets/Images/brand/diamond.png"),
        },
        {
          index: 26,
          name: "zs",
          pic: require("../assets/Images/brand/zs.png"),
        },
        {
          index: 27,
          name: "chyela",
          pic: require("../assets/Images/brand/chyela.png"),
        },
        {
          index: 28,
          name: "peakperformance",
          pic: require("../assets/Images/brand/peakperformance.png"),
        },
        {
          index: 33,
          name: "precor",
          pic: require("../assets/Images/brand/precor.png"),
        },
        {
          index: 29,
          name: "冰洁",
          pic: require("../assets/Images/brand/bj.png"),
        },
        {
          index: 30,
          name: "雪中飞",
          pic: require("../assets/Images/brand/xzf.png"),
        },
        {
          index: 31,
          name: "阿仕顿",
          pic: require("../assets/Images/brand/artsdon.png"),
        },
        {
          index: 32,
          name: "minipeace",
          pic: require("../assets/Images/brand/minipeace1.png"),
        },
        {
          index: 34,
          name: "xsdgj",
          pic: require("../assets/Images/brand/xsdgj.png"),
        },
        {
          index: 35,
          name: "witera",
          pic: require("../assets/Images/brand/witera.png"),
        },
        {
          index: 36,
          name: "ke",
          pic: require("../assets/Images/brand/ke.png"),
        },
        {
          index: 37,
          name: "lee",
          pic: require("../assets/Images/brand/lee.png"),
        },
        {
          index: 38,
          name: "vaude",
          pic: require("../assets/Images/brand/vaude.png"),
        },
        {
          index: 39,
          name: "columbia",
          pic: require("../assets/Images/brand/columbia.png"),
        },
        {
          index: 40,
          name: "mavic",
          pic: require("../assets/Images/brand/mavic.png"),
        },
        {
          index: 41,
          name: "ledin",
          pic: require("../assets/Images/brand/ledin.png"),
        },
        {
          index: 42,
          name: "bbt",
          pic: require("../assets/Images/brand/bbt.png"),
        },
        {
          index: 43,
          name: "jeeploud",
          pic: require("../assets/Images/brand/jeeploud.png"),
        },
        {
          index: 44,
          name: "joma",
          pic: require("../assets/Images/brand/joma.png"),
        },
        {
          index: 45,
          name: "discovery",
          pic: require("../assets/Images/brand/discovery.png"),
        },
        {
          index: 46,
          name: "mammut",
          pic: require("../assets/Images/brand/mammut.png"),
        },
        {
          index: 47,
          name: "dwty",
          pic: require("../assets/Images/brand/dwty.png"),
        },
        {
          index: 48,
          name: "ythw",
          pic: require("../assets/Images/brand/ythw.png"),
        },
        {
          index: 49,
          name: "whty",
          pic: require("../assets/Images/brand/whty.png"),
        },
        {
          index: 50,
          name: "yzty",
          pic: require("../assets/Images/brand/yzty.png"),
        },
        {
          index: 51,
          name: "fjjt",
          pic: require("../assets/Images/brand/fjjt.png"),
        },
        {
          index: 52,
          name: "nbqyj",
          pic: require("../assets/Images/brand/nbqyj.png"),
        },
        {
          index: 53,
          name: "zdhw",
          pic: require("../assets/Images/brand/zdhw.png"),
        },
        {
          index: 54,
          name: "wlmqyph",
          pic: require("../assets/Images/brand/wlmqyph.png"),
        },
        {
          index: 55,
          name: "shxj",
          pic: require("../assets/Images/brand/shxj.png"),
        },
        {
          index: 56,
          name: "shky",
          pic: require("../assets/Images/brand/shky.png"),
        },
        {
          index: 57,
          name: "hbbc",
          pic: require("../assets/Images/brand/hbbc.png"),
        },
        {
          index: 58,
          name: "indicia",
          pic: require("../assets/Images/brand/indicia.png"),
        },
        {
          index: 59,
          name: "delicates",
          pic: require("../assets/Images/brand/delicates.png"),
        },
        {
          index: 60,
          name: "hfgc",
          pic: require("../assets/Images/brand/hfgc.png"),
        },
        {
          index: 61,
          name: "yx",
          pic: require("../assets/Images/brand/yx.png"),
        },
        {
          index: 62,
          name: "jszh",
          pic: require("../assets/Images/brand/jszh.png"),
        },
        {
          index: 63,
          name: "hblc",
          pic: require("../assets/Images/brand/hblc.png"),
        },
        {
          index: 64,
          name: "material",
          pic: require("../assets/Images/brand/material.png"),
        },
        {
          index: 65,
          name: "szmm",
          pic: require("../assets/Images/brand/szmm.png"),
        },
        {
          index: 66,
          name: "xznj",
          pic: require("../assets/Images/brand/xznj.png"),
        },
        {
          index: 67,
          name: "lsmy",
          pic: require("../assets/Images/brand/lsmy.png"),
        },
        {
          index: 68,
          name: "keepright",
          pic: require("../assets/Images/brand/keepright.png"),
        },
        {
          index: 69,
          name: "lenle",
          pic: require("../assets/Images/brand/lenle.png"),
        },
        {
          index: 70,
          name: "artyaya",
          pic: require("../assets/Images/brand/artyaya.png"),
        },
        {
          index: 71,
          name: "mumufamily",
          pic: require("../assets/Images/brand/mumufamily.png"),
        },
        {
          index: 72,
          name: "glodwin",
          pic: require("../assets/Images/brand/glodwin.png"),
        },
        {
          index: 73,
          name: "金阳光",
          pic: require("../assets/Images/brand/jinyangguang.png"),
        },
        {
          index: 74,
          name: "maiaactive",
          pic: require("../assets/Images/brand/maiaactive.png"),
        },
      ],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: false }).init();
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.zj_customerLogo {
  width: 100%;
  height: 100%;
  .title1{
    width: 100%;
  height: 4rem;
  }
  .title2{
    width: 100%;
  height:2rem;
  }
  .customerLogo{
    width: 70%;
  height: 30rem;
  margin-left: 17%;
  .logo{
    width: 14%;
    height: 7rem;
    box-shadow: 0 0 10px rgba(85, 85, 85, 0.2);
    float: left;
    margin: 2% 2.5% 0 0;
    img{
      width: 70%;
    }
  }
  }
}
</style>
