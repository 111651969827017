<template>
  <!--客户案例 客户logo-->
  <div class="zj_classiccustomerLogo box_shadow1">
    <div
      class="title1 flexcc font16rem wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
     {{$t('持续创新赋能 助力品牌成长')}} 
    </div>
    <div
      class="title2 flexcc font10rem wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      {{$t('十九年的技术和服务沉淀 成就知名品牌坚定选择')}}
    </div>
    <div class="customerLogo">
      <div
        class="logo flexcc wow"
        v-for="item in logodata"
        :key="item.index"
        :class="
          item.index <= 4 || (item.index >= 9 && item.index <= 12)
            ? 'bounceInLeft'
            : 'bounceInRight'
        "
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img :src="item.pic" />
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_classiccustomerLogo",
  data() {
    return {
      logodata: [
        {
          index: 2,
          name: "fila",
          pic: require("../assets/Images/brand/fila.png"),
        },
        {
          index: 3,
          name: "安踏",
          pic: require("../assets/Images/brand/anta.png"),
        },
        {
          index: 16,
          name: "九木",
          pic: require("../assets/Images/brand/jm.png"),
        },
        {
          index: 4,
          name: "木槿",
          pic: require("../assets/Images/brand/mumuso.png"),
        },
        // {
        //   index: 5,
        //   name: "可隆",
        //   pic: require("../assets/Images/brand/kolon.png"),
        // },
        {
          index: 6,
          name: "小笑牛",
          pic: require("../assets/Images/brand/kingkow.png"),
        },
        {
          index: 7,
          name: "迪桑特",
          pic: require("../assets/Images/brand/descente.png"),
        },
        {
          index: 8,
          name: "amersports",
          pic: require("../assets/Images/brand/amersports.png"),
        },
        // {
        //   index: 9,
        //   name: "suunto",
        //   pic: require("../assets/Images/brand/suunto.png"),
        // },
        {
          index: 10,
          name: "precor",
          pic: require("../assets/Images/brand/precor.png"),
        },
        {
          index: 11,
          name: "peakperformance",
          pic: require("../assets/Images/brand/peakperformance.png"),
        },
        {
          index: 12,
          name: "始祖鸟",
          pic: require("../assets/Images/brand/arcteryx.png"),
        },
        // {
        //   index: 13,
        //   name: "萨洛蒙",
        //   pic: require("../assets/Images/brand/salomon.png"),
        // },
        {
          index: 1,
          name: "波司登",
          pic: require("../assets/Images/brand/bsd.png"),
        },
        {
          index: 14,
          name: "绿叶",
          pic: require("../assets/Images/brand/ly.png"),
        },
        {
          index: 15,
          name: "阿仕顿",
          pic: require("../assets/Images/brand/artsdon.png"),
        },
        {
          index: 17,
          name: "delicates",
          pic: require("../assets/Images/brand/delicates.png"),
        },
        {
          index: 18,
          name: "太平鸟",
          pic: require("../assets/Images/brand/peacebird.png"),
        },
        {
          index: 19,
          name: "晨光",
          pic: require("../assets/Images/brand/cg.png"),
        },
        {
          index: 20,
          name: "森马",
          pic: require("../assets/Images/brand/sm1.svg"),
        },
        {
          index: 21,
          name: "balabala",
          pic: require("../assets/Images/brand/balabala.png"),
        },
        {
          index: 22,
          name: "雅诗兰黛",
          pic: require("../assets/Images/brand/EsteeLauder.png"),
        },
        {
          index: 23,
          name: "海棠铺子",
          pic: require("../assets/Images/brand/htpz.png"),
        },
        {
          index: 24,
          name: "戎美",
          pic: require("../assets/Images/brand/rm1.jpg"),
        },
        {
          index: 25,
          name: "wilson",
          pic: require("../assets/Images/brand/wilson.png"),
        },
        {
          index: 26,
          name: "minipeace",
          pic: require("../assets/Images/brand/minipeace1.png"),
        },
        {
          index: 27,
          name: "salomon",
          pic: require("../assets/Images/brand/salomon.png"),
        },
        {
          index: 28,
          name: "atomic",
          pic: require("../assets/Images/brand/atomic.png"),
        },
        {
          index: 29,
          name: "material",
          pic: require("../assets/Images/brand/material.png"),
        },
        {
          index: 30,
          name: "ledin",
          pic: require("../assets/Images/brand/ledin.png"),
        },
        {
          index: 31,
          name: "hotwind",
          pic: require("../assets/Images/brand/hotwind.png"),
        },
        {
          index: 32,
          name: "outdoor",
          pic: require("../assets/Images/brand/outdoor.png"),
        },
        {
          index: 33,
          name: "冰洁",
          pic: require("../assets/Images/brand/bj.png"),
        },
        {
          index: 34,
          name: "mumufamily",
          pic: require("../assets/Images/brand/mumufamily.png"),
        },
        {
          index: 35,
          name: "雪中飞",
          pic: require("../assets/Images/brand/xzf.png"),
        },
        {
          index: 30,
          name: "mavic",
          pic: require("../assets/Images/brand/mavic.png"),
        },
        {
          index: 31,
          name: "diamond",
          pic: require("../assets/Images/brand/diamond.png"),
        },
        {
          index: 32,
          name: "帮帮堂",
          pic: require("../assets/Images/brand/bbt.png"),
        },
        {
          index: 33,
          name: "新时代国际",
          pic: require("../assets/Images/brand/xsdgj.png"),
        },
        {
          index: 34,
          name: "ke",
          pic: require("../assets/Images/brand/ke.png"),
        },
        {
          index: 35,
          name: "chyela",
          pic: require("../assets/Images/brand/chyela.png"),
        },
        {
          index: 36,
          name: "mavic",
          pic: require("../assets/Images/brand/indicia.png"),
        },
        {
          index: 37,
          name: "keepright",
          pic: require("../assets/Images/brand/keepright.png"),
        },
        {
          index: 38,
          name: "lenle",
          pic: require("../assets/Images/brand/lenle.png"),
        },
        {
          index: 39,
          name: "兰蔻",
          pic: require("../assets/Images/brand/lancome.png"),
        },
        {
          index: 40,
          name: "witera",
          pic: require("../assets/Images/brand/witera.png"),
        },
        {
          index: 41,
          name: "TheGreenParty",
          pic: require("../assets/Images/brand/TheGreenParty.png"),
        },
        {
          index: 50,
          name: "artyaya",
          pic: require("../assets/Images/brand/artyaya.png"),
        },
        {
          index: 42,
          name: "mollyWood",
          pic: require("../assets/Images/brand/mollyWood.png"),
        },
        {
          index: 43,
          name: "columbia",
          pic: require("../assets/Images/brand/columbia.png"),
        },
        {
          index: 44,
          name: "mammut",
          pic: require("../assets/Images/brand/mammut.png"),
        },
        {
          index: 45,
          name: "lee",
          pic: require("../assets/Images/brand/lee.png"),
        },
        {
          index: 46,
          name: "vaude",
          pic: require("../assets/Images/brand/vaude.png"),
        },
        {
          index: 47,
          name: "jeeploud",
          pic: require("../assets/Images/brand/jeeploud.png"),
        },
        {
          index: 48,
          name: "joma",
          pic: require("../assets/Images/brand/joma.png"),
        },
        {
          index: 49,
          name: "discovery",
          pic: require("../assets/Images/brand/discovery.png"),
        },
        {
          index: 51,
          name: "glodwin",
          pic: require("../assets/Images/brand/glodwin.png"),
        },
        {
          index: 52,
          name: "金阳光",
          pic: require("../assets/Images/brand/jinyangguang.png"),
        },
        {
          index: 53,
          name: "maiaactive",
          pic: require("../assets/Images/brand/maiaactive.png"),
        },
      ],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: false }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_classiccustomerLogo {
  width: 70%;
  height: 100%;
  margin: auto;
  .title1 {
    width: 100%;
    height: 4rem;
    padding-top: 2rem;
  }
  .title2 {
    width: 100%;
    height: 2rem;
  }
  .customerLogo {
    width: 95%;
    height: 80%;
    margin-left: 4%;
    .logo {
      width: 22.5%;
      height: 6rem;
      float: left;
      margin: 2% 2.5% 0 0;
      img {
        width: 45%;
      }
    }
  }
}
</style>
